<!--
 * @Descripttion: 登录页面组件
 * @Author: congz
 * @Date: 2020-06-11 11:08:34
 * @LastEditors: congz
 * @LastEditTime: 2020-08-19 10:14:30
--> 

<template>
  <div class="login" :style="{height: loginBoxHeight + 'px'}">
    <div>
      <el-card class="box-card" shadow="hover">
        <router-link to="/">
          <img width="120" src="../assets/imgs/clogo.png" alt />
        </router-link>
        <div class="clearfix">
          <span>使用尚选账号 登录网站</span>
        </div>
        <div class="item">
          <el-form :model="form" status-icon :rules="rules" ref="form" @keyup.native.enter="login('form')">
            <el-form-item prop="mobile">
              <el-input v-model="form.mobile" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input type="password" v-model="form.password" placeholder="密码"></el-input>
            </el-form-item>
          </el-form>
          <!-- <div id="captcha">
            <p id="wait">正在加载验证码...</p>
          </div> -->
          <div style="margin-top:15px">
            <el-button class="btn-gradient blue block" :loading="loginLoading" @click="login('form')">登录</el-button>
          </div>
          <el-link
            type="primary"
            href="/#/register"
            style="float:right;margin-bottom:10px;"
          >没有账号？请先注册></el-link>
        </div>
        <!-- <div class="line"></div>
        <div class="logo">
          <div class="logo-info">其他账号登录:</div>
          <div class="logo-login">
            <div @click="qqInit">
              <img src="../assets/imgs/QQlogo.png" alt />
            </div>
          </div>
        </div> -->
      </el-card>
    </div>
  </div>
</template>
<script src="../assets/gt.js"></script>
<script>
import { mapActions } from 'vuex'
import * as userAPI from '@/api/users'
import { setToken } from '@/utils/auth.js'
import categoryAPI from '@/api/category.js'
require('../assets/gt.js')
var captcha
export default {
  name: 'Login',
  data() {
    var validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else if (value.length !== 11) {
        callback(new Error('手机号长度应为11位'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else if (value.length < 6 || value.length > 18) {
        callback(new Error('密码长度需在6到18之间'))
      }
      callback()
    }
    return {
      form: {
        mobile: '',
        password: '',
        challenge: '',
        validate: '',
        seccode: ''
      },
      rules: {
        mobile: [{ validator: validatePhone, trigger: 'blur' }],
        password: [{ validator: validatePass, trigger: 'blur' }]
      },
		screenHeight: 0,
		loginLoading: false
    }
  },
	mounted() {
		this.screenHeight = window.innerHeight; // 获取屏幕高度
		// 监听窗口大小变化，更新屏幕高度
		window.addEventListener('resize', this.handleResize);
		console.log(this.screenHeight)
	},
	computed: {
		loginBoxHeight() {
			return this.screenHeight - 285  > 350 ? this.screenHeight - 285 : 350
		}
	},
	beforeDestroy() {
		// 清除监听器
		window.removeEventListener('resize', this.handleResize);
	},
  methods: {
    ...mapActions(['setUser', 'setCategory']),
	handleResize() {
		this.screenHeight = window.innerHeight;
	},
    login(formName) {
		if (this.loginLoading) {
			return
		}
      this.$refs[formName].validate(valid => {
        if (!valid) {
          return
        }
		// console.log(1)
  //       var result = captcha.getValidate()
  //       if (!result) {
  //         this.notifyError('请验证', null)
  //         return
  //       }
		// console.log(2)
        // ;(this.form.challenge = result.geetest_challenge),
        //   (this.form.validate = result.geetest_validate),
        //   (this.form.seccode = result.geetest_seccode),
		this.loginLoading = true
          userAPI
            .postLogin(this.form)
            .then(res => {
				this.loginLoading = false
              if (res.status_code === 404) {
                this.notifyError('验证失败', res.msg)
              } else if (res.status_code === 200) {
                // 登录信息存到本地
                let user = JSON.stringify(res.data.user)
                localStorage.setItem('user', user)
                localStorage.setItem('token', JSON.stringify(res.data.token))
				setToken(res.data.token)
                // 登录信息存到vuex
                this.setUser(res.data.user)
				// this.getCate()
                // 弹出通知框提示登录成功信息
                // this.notifySucceed('登录成功')
                this.$router.push({
					name: 'Home'
                })
              } else {
                // this.notifyError('登录失败', res.msg)
              }
            })
            .catch(error => {
				this.loginLoading = false
              // this.notifyError('登录失败', error)
            })
      })
    },
	getCate() {
		categoryAPI.getCategoryList({ page: 1, limit: 100 }).then(res => {
			if (res.status_code == 200) {
				// const val = {
				// 	id: 0,
				// 	name: '全部'
				// }
				const cate = res.data.data
				// cate.unshift(val)
				this.setCategory(cate)
			}
		}).catch(err => {
		})
	},
    qqInit() {
      userAPI.qqInit().then(res => {
        if (res.status === 200) {
          window.location.href = res.data
        }
      })
    },
    init_geetest() {
      userAPI.geetest().then(res => {
        window.initGeetest(
          {
            gt: res.gt,
            challenge: res.challenge,
            new_captcha: res.new_captcha,
            offline: !res.success,
            product: 'popup',
            width: '100%'
          },
          function(captchaObj) {
            captcha = captchaObj
            captchaObj.appendTo('#captcha')
            captchaObj.onReady(function() {
              document.getElementById('wait').style.display = 'none'
            })
          }
        )
      })
    }
  },
  // mounted() {
    // this.init_geetest()
  // },

  components: {}
}
</script>

<style scoped>
@import '../assets/css/button.css';
.text {
  font-size: 14px;
}

.item {
  margin: 0 auto;
  width: 300px;
}

.clearfix {
  font-size: 20px;
  margin-bottom: 20px;
}
.login{
	position: relative;
}
.box-card {
	text-align: center;
    width: 400px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -200px;
    margin-top: -160px;
}
.box-card .line {
  height: 30px;
  width: 300px;
  margin: 0 auto;
  border-bottom: 1px solid #c0c0c0;
}
/*其他账号登录*/
.box-card .logo {
  width: 300px;
  margin: 0 auto;
  height: 30px;
  display: flex;
  align-items: center;
}
.box-card .logo .logo-info {
  font-size: 14px;
  color: #757575;
}
.box-card .logo .logo-login {
  height: 15px;
  margin-left: 10px;
}
/*其他账号登录END*/
</style>
